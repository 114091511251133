$sourcesanspro-font-path: "/assets/fonts/sourcesanspro/" !default;

/*** Source Sans Pro ***/
@font-face {
    font-family: 'Source Sans Pro Black';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-black-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Black';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-black-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Black';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-black-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-black-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro Black Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-blackitalic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-blackitalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-blackitalic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-bold-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-bold-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Bold Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-bolditalic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-bolditalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-bolditalic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Extra Light';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-extralight-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-extralight-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-extralight-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Extra Light Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-extralightitalic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-extralightitalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-extralightitalic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-italic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-italic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-italic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Light';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-light-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-light-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Light Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-lightitalic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-lightitalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-lightitalic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Regular';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-regular-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-regular-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-semibold-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-semibold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-semibold-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro SemiBold Italic';
    src: url("#{$sourcesanspro-font-path}sourcesanspro-semibolditalic-webfont.ttf") format("truetype"),
         url('#{$sourcesanspro-font-path}sourcesanspro-semibolditalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sourcesanspro-font-path}sourcesanspro-semibolditalic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
