$discover-place-card-width: 320px;

._discover-place-card {
    position: relative;
    box-sizing: border-box;
    height: 380px;
    width: $discover-place-card-width;
    -webkit-tap-highlight-color: transparent;
    cursor:pointer;
    scroll-snap-align: center;
    margin-right: 16px; 
    overflow: hidden;
    border-radius: $radius-extra-large;
    transition: all $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);

    .media {
        position: relative;
        height: 380px;
        width: $discover-place-card-width;
        border-radius: $radius-extra-large;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #000000;
        img {
            height: 380px;
            width: $discover-place-card-width;
            object-fit: cover;
            border-radius: $radius-extra-large;
            overflow: hidden;
            opacity: 0.95;
        }
    }


    .header {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 64px;
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
       // padding-left: 16px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        background-color: rgba(0,0,0,0.3);
        border-top-left-radius: $radius-extra-large;
        border-top-right-radius: $radius-extra-large;
      
        .icon-wrapper {
          order: 1;
          flex: 0 0 auto;
          overflow: hidden;
          display: inline-block;
          box-sizing: border-box;
          padding-left: 16px;
          line-height: 64px;
          color: #FFFFFF;
          box-sizing: border-box;
          text-align: center;
      
          i {
            font-size: 28px;
            line-height: inherit;
            margin-right: 16px;
          }
        }
      
        
        .text {
          order: 2;
          flex: 1 0;
          height: 64px;
          box-sizing: border-box;
          overflow: hidden;
          float: left;
         // padding-top: 6px;
          .title {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 40px;
            font-weight: 500;
            opacity: 0.9;
          }
      
          .subtitle {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 0;
            vertical-align: top;
            font-weight: 600;
          }
        }
      
    }
      
    .content {
        position: relative;

        .circle {
            position: absolute;
            bottom: -250px;
            left: -120px;
            background-color: rgba(0,0,0,0.5);
            width: 400px;
            height: 400px;
            border-radius: 50%;
        }

        .heading {
            position: absolute;
            bottom: 56px;
            left: 24px;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: 600;
            opacity: 0.9;
        }
        .subheading {
            position: absolute;
            bottom: 32px;
            left: 24px;
            font-size: 16px;
            color: #FFFFFF;
            font-weight: 200;
            opacity: 0.8;
        }

        .little-circle {
            position: absolute;
            bottom: -300px;
            right: -120px;
            background-color: rgba(255,255,255,0.3);
            width: 400px;
            height: 400px;
            border-radius: 50%;
        }

    }

}