$sass-toolbar-height: 88px;
$sass-toolbar-action-height: 64px;


._collection-management-toolbar {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    color: rgba(0,0,0,0.8);
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: $sass-toolbar-height;
    line-height: $sass-toolbar-height;
    z-index: 1;
    transition: all $motion-duration-short-3 cubic-bezier(0.3, 0, 0.8, 0.15);
    transform: translate3d();

    .action-item {
        flex: 1 0 auto;
        order: 1;
        margin-right: 12px;
    }  


    .fill-remaining-space {
        order: 1;
        flex: 1 0 auto;
    }

    .search-wrapper {
        flex: 1 0 auto;
//        flex: 0 0 auto;
        order: 2;
        float: left;
        padding-top: 16px;
        user-select: none;
    }

    .action-items {
        flex: 0 0 auto;
        order: 3;
        float: right;
        padding-left: 36px;
        padding-right: 36px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .item {
            width: 48px;
            height: $sass-toolbar-height;
            line-height: $sass-toolbar-height;
            display: inline-block;
            text-transform: uppercase;
            color: #ffffff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            padding-top: 24px;
            overflow: hidden;
        }
    }

    .action-button-wrapper {
        height: $sass-toolbar-height;
        flex: 0 0 auto;
        order: 5;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 22px;
        padding-left: 4px;
        padding-right: 4px;

        .close-icon {
            height: $sass-toolbar-height;
            line-height: $sass-toolbar-height;
            font-size: 32px;
            font-weight: 700;
        }
    }

    &.disabled {
        opacity: 0.3;
    }
}

._collection-management-action-bar {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    color: rgba(0,0,0,0.8);
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: $sass-toolbar-height;
    line-height: $sass-toolbar-height;
    z-index: 1;
    transition: all $motion-duration-short-3 cubic-bezier(0.3, 0, 0.8, 0.15);
    transform: translate3d();

    .actions-wrapper {
        flex: 1 0 auto;
        order: 1;
        padding-top: 24px;
        user-select: none;
        text-align: right;
        padding-right: 32px;
        box-sizing: border-box;

        .item {
            display: inline-block;
            padding-left: 16px;
        }
    }

    .close-wrapper {
        height: $sass-toolbar-height;
        flex: 0 0 auto;
        order: 2;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;

        .close-icon {
            height: $sass-toolbar-height;
            line-height: $sass-toolbar-height;
            font-size: 32px;
            font-weight: 700;
        }
    }
   
}






._action-icon-button {
    @extend .grid-element;
    text-align: center;
    border-radius: 2px;
    box-sizing: 		border-box;
    width: 48px;
    height: 48px;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    .icon-wrapper {
        text-align: center;
        margin-bottom: 6px;
        vertical-align: top;

        i {
          font-size: 24px;
        }
    }

    .label {
        width: 100%;
        text-align: center;
        @extend ._label-small;
    }
}




/*

._collection-management-action-bar {
    width:  100%;
    height: $sass-toolbar-action-height;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    @extend .all-motion-animation;
    transform: translate3d();

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .item {
        margin-right: 12px;
        display: inline-block;
    }  
       
    .item-close {
        margin-left: 24px;
        display: inline-block;
    }  
}

*/