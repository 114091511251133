
.conventional-input-field-wrapper {
  margin-bottom:    24px;
  position: relative;

  .conventional-input-field {
    width:          100%;
    line-height:    32px;
    border-radius:  4px;
    border:         1px solid rgba(0,0,0,0.2);
    padding:        8px;
    padding-left:   16px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @extend ._body-large;


    &:focus {
      border-radius:  4px;
      border:         1px solid rgba(0,0,0,0.2);
    }

  }


  //show-hide password icon
  .trailing-icon-wrapper {
    width:          32px;
    float:          right;
    position:       absolute;
    top:            16px;
    right:          0;

    //get rid of text selection
    -webkit-touch-callout: none; // iOS Safari
      -webkit-user-select: none; // Safari
       -khtml-user-select: none; // Konqueror HTML
         -moz-user-select: none; // Firefox
          -ms-user-select: none; // Internet Explorer/Edge
              user-select: none; // Non-prefixed version, currently supported by Chrome and Opera


    i {
      font-size:    22px;
    }

  }


  .conventional-input-field-label {
    margin-bottom:         16px;
    font-weight:           500;
    opacity:               1;
  }

  .conventional-input-field-feedback-text {
    margin-top:         8px;
  }
}


.conventional-select-field-wrapper {
  border:             none;
  border-radius:      12px;
  height:             44px;
  line-height:        44px;
  padding:            0 16px;
//  background-color:   $primary-color;
  background-color:   rgb(255,0,122);
  color:              #FFFFFF;
  @extend             .shadow-1;

  //get rid of text selection
  -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Safari
     -khtml-user-select: none; // Konqueror HTML
       -moz-user-select: none; // Firefox
        -ms-user-select: none; // Internet Explorer/Edge
            user-select: none; // Non-prefixed version, currently supported by Chrome and Opera



  // font
  vertical-align: middle;
  font-size:    16px;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  display: inline-block;

  i {
    font-size: 28px;
    line-height: 44px;
    padding-left: 8px;
    vertical-align: middle;
  }


  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

}
