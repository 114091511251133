._search-bar-container {

    position: relative;
    transition: all $motion-duration-long-3 cubic-bezier(0.2, 0.0, 0, 1.0);

    &._search-container-mode {
        width: 100%;
        border-radius: 0;
        height: 400px;
        z-index: 1000;

        .input-field-wrapper {
            order: 2;
            flex: 1 0 auto;
            height: 56px;
            overflow: hidden;
        }
    
        .trailing-icon-wrapper {
            order: 3;
            flex: 0 0 auto;
            .item {
                width: 24px;
                margin-left: 16px;
                margin-right: 16px;
                box-sizing: border-box;
                overflow: hidden;
                cursor: pointer;
                text-align: center;
                display: inline-block;
                i {
                    font-size: 28px;
                    line-height: 60px;
                }
            }
    
            .avatar {
                
            }
    
        }
    
        .input-field {
            width: 100%;
            height: 56px;
            overflow: hidden;
            background-color: rgba(0,0,0,0);
            border: none;
            @extend ._body-large;
    
            &:focus {
                outline: none;
            }
        }        
    }

    &._search-bar-mode {
        width: 100%;
        height: 56px;
        border-radius: $radius-extra-large;
        box-sizing: border-box;
        position: relative;
        line-height: 56px;
        border: none;
        display:          flex;
        flex-wrap:        nowrap;
        flex-direction:   row;
        align-items:      stretch;
        overflow:         hidden;
        
        .leading-icon {
            padding-left: 16px;
          //  padding-right: 24px;
            box-sizing: border-box;
            overflow: hidden;
            flex: 0 0 56px;
            order: 1;
            i {
                font-size: 28px;
                line-height: 60px;
            }
        }
    
        .input-field-wrapper {
            order: 2;
            flex: 1 0 auto;
            height: 56px;
            overflow: hidden;
        }
    
        .trailing-icon-wrapper {
            order: 3;
            flex: 0 0 auto;
            .item {
                width: 24px;
                margin-left: 16px;
                margin-right: 16px;
                box-sizing: border-box;
                overflow: hidden;
                cursor: pointer;
                text-align: center;
                display: inline-block;
                i {
                    font-size: 28px;
                    line-height: 60px;
                }
            }
    
            .avatar {
                
            }
    
        }
    
        .input-field {
            width: 100%;
            height: 56px;
            overflow: hidden;
            background-color: rgba(0,0,0,0);
            border: none;
            @extend ._body-large;
    
            &:focus {
                outline: none;
            }
        }   
    }

}
