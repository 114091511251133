.mdicon-accelerate {
	transform:        translateZ(0);  //force the GPU to render
}

.mdicon-in-out-effect {
	animation: mdicon-in-out-effect-anim 150ms cubic-bezier(0.24, 0, 0.38, 1);
}
@keyframes mdicon-in-out-effect-anim {
  0% {
    opacity: 0.1;
    transform: scale(0.5);
  }
  80% {
    opacity: 0.3;
    transform: scale(1.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


$labeled-icon-btn-height:               72px;
.labeled-icon-btn {
	width:						100%;
	height:						$labeled-icon-btn-height;
	text-align: 			center;
	padding-top:			18px;
//	padding-bottom:		2px;
	vertical-align:     middle;

	display: flex;
  flex-direction: column;
  align-items: center;

	.icon {
		font-size:	20px;
		line-height: 0;
		margin-top: 6px;
	}

	.label {
	  margin-top: 	20px;
		line-height: 0;
	}
}



.anim-spin-clockwise {
	animation: _animationSpinClockwise 350ms ease-in-out;
}

.anim-spin-clockwise-fast {
	animation: _animationSpinClockwise 150ms ease-in-out;
}


@keyframes _animationSpinClockwise {
	0% {
		opacity: 1;
 		transform: rotate(0deg) translateZ(0);
	}

	50% {
		opacity: 0.1;
 		transform: rotate(180deg) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: rotate(360deg) translateZ(0);
	}
}

.anim-spin-counterclockwise {
	animation: animationSpinCounterClockwise 350ms ease-in-out;
}

@keyframes animationSpinCounterClockwise {
	0% {
		opacity: 0.1;
		transform: rotate(-360deg) translateZ(0);
	}
	100% {
		opacity: 1;
		transform: rotate(0deg) translateZ(0);
	}
}
