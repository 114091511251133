
._flight-price-tile-card {
  position: relative;
  box-sizing: border-box;
  vertical-align:top;
  height: 112px;
  width: 100%;
  margin-right: 16px;
  -webkit-tap-highlight-color: transparent;
  cursor:pointer;
  overflow: hidden;
  transition: all $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);
  border-radius: $radius-extra-large;

  
}
  

