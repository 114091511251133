
._navigation-rail {
    width: 80px;
    height: 100vh;
    padding-top: 24px;
    position: absolute;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 1010;

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .menu {
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 12px;
        font-size: 24px;
        box-sizing: border-box;
        overflow: hidden;

        //gt rid of text selection
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }

    .fab-wrapper {
        padding-left: 12px;
        margin-bottom: 56px;
    }

    .destinations {
      padding-bottom: 56px;
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  

    &.extend {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      width: 320px;
      transition: width $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
      @extend .shadow-1;
    }

    &.retract {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 80px;
      transition: width $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
      @extend .shadow-0;
    }
    
  }
  


  .extended-menu-container {
    position: fixed;
    left: 80px;
    top: 0;
    width: 240px;
    height: 100vh;
    padding-top: 28px;
    //padding-left: 24px;
    box-sizing: border-box;
    z-index: 1000;
    transition: left $motion-duration-long-2 cubic-bezier(0.2, 0.0, 0, 1.0);


    &.show {
      left: 80px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      @extend .shadow-1;
    }
    &.hide {
      left: -240px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      @extend .shadow-0;

    }

  }

