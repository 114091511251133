$merriweather-font-path: "/assets/fonts/merriweather/" !default;


@font-face {
    font-family: 'Merriweather Regular';
    src: url("#{$merriweather-font-path}merriweather-regular-webfont.ttf");
}

@font-face {
    font-family: 'Merriweather Italic';
    src: url("#{$merriweather-font-path}merriweather-italic-webfont.ttf");
}
