.shadow-0 {
  box-shadow: none !important;
}
.shadow-1 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.shadow-2 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}
.shadow-3 {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.19);
}
.shadow-4 {
  box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.25), 0 10px 10px 0 rgba(0, 0, 0, 0.22);
}
.shadow-5 {
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22);
}

//////////////////////////////////////
/// Scale - Apply for elevation
//////////////////////////////////////
/*
.scale-0 {
  transform: scale(1);
}
.scale-1 {
  transform: scale(1.01);
}
.scale-2 {
  transform: scale(1.02);
}
.scale-3 {
  transform: scale(1.03);
}
.scale-4 {
  transform: scale(1.04);
}
.scale-5 {
  transform: scale(1.05);
}
*/
/********************************
* Legacy - Material One Shadows
********************************/

.shadow01-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.shadow01-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.shadow01-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.shadow01-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.shadow01-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.shadow01-1-white {
  box-shadow: 0 2px 5px 0 rgba(255, 255, 255, 0.16), 0 2px 10px 0 rgba(255, 255, 255, 0.12);
}
.shadow01-4-white {
  box-shadow: 0 16px 28px 0 rgba(255, 255, 255, 0.22), 0 25px 55px 0 rgba(255, 255, 255, 0.21);
}
