
._list-detail-layout {
    height: 100vh;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    .list-pane {
        order: 1;
        flex: 0 0 320px;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        margin-right: 24px;
        transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
    }

    .detail-pane {
        order: 2;
        flex: 1;
       // overflow: hidden;
        min-height: 0;
        box-sizing: border-box;
        border-radius: $radius-large;
        transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
    }

}