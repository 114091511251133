///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.two-item-list-tile {
  @extend .list-tile-item;
  height: 64px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 16px;
  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  .content {
    order: 1;
    flex: 1 0;
    height: 64px;
    box-sizing: border-box;
    overflow: hidden;
    float: left;

    .top-line {
      font-size: 16px;
      color: #000000;
      line-height: 44px;
      font-weight: 600;
    }

    .bottom-line {
      font-size: 16px;
      color: #000000;
      line-height: 0;
      vertical-align: top;
      font-weight: 400;
      opacity: 0.8;
    }
  }

  .secondary-action {
    order: 2;
    flex: 0 0 auto;
    float: right;
    @extend .list-tile-secondary-action-64;
  }
}
