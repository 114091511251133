///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.two-item-list-tile-leading-image {
  @extend .list-tile-item;
  height: 72px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  &.wide {
    .image-wrapper {
      padding-left: 0;
      padding-top: 8px;
      img {
        min-width: 100px;
        max-width: 100px;
        min-height: 56px;
        max-height: 56px;
  
      }
    }
    .content {
      flex: 0 0 244px;
    }
  }

  .image-wrapper {
    order: 1;
    flex: 0 0 auto;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 16px;
    box-sizing: border-box;

    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      object-fit: fill;
      margin-right: 16px;
    }
  }


  .content {
    order: 2;
    flex: 1 0;
    height: 72px;
    box-sizing: border-box;
    overflow: hidden;
    float: left;
    padding-top: 8px;

    .top-line {
      font-size: 16px;
      color: #000000;
      line-height: 28px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .bottom-line {
      font-size: 16px;
      color: #000000;
      line-height: 16px;
      vertical-align: top;
      font-weight: 400;
      opacity: 0.8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .secondary-action {
    order: 3;
    flex: 0 0 auto;
    float: right;
    @extend .list-tile-secondary-action-72;
  }
}
