
._sp-navigation-drawer {
    width: 100%;
    height: 100vh;
    border-top-right-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    overflow: hidden;

    .header {
        box-sizing: border-box;
        margin-top: 24px;
        margin-bottom: 16px;
        margin-left: 16px;
        margin-right: 16px;
        @extend ._title-small;
        text-align: center;
        img {
            width: 80%;
            height: auto;
        }
    }

    .menu-control {
        height: 56px;
        width: 100%;
        padding-left: 16px;
        line-height: 56px;
        color: #000000;
      
        i {
        font-size: 20px;
        line-height: inherit;
        margin-right: 28px;
        }      
    }


    .menu-container {
        list-style: none;
        box-sizing: border-box;
        transform: translateZ(0);
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    
}


._sp-sn-menu-item {
    @extend .list-tile-item;
    height: 48px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    padding-left: 16px;
    border-radius: 24px;
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  
    .label {
      order: 1;
      flex: 1 0;
      color: #000000;
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend ._title-large;
    }
  
    .secondary-action {
      order: 2;
      flex: 0 0 auto;
      @extend .list-tile-secondary-action-48;
    }
}
