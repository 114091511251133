$progress-bar-backgroundcolor:  #f2f2f2;

._linear-progress-bar {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: $progress-bar-backgroundcolor;
  border-radius: 2px;
  margin: 4px 0 2px 0;
  overflow: hidden;

  .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $progress-bar-backgroundcolor;
  }
  .indeterminate {
    background-color: $progress-bar-backgroundcolor;
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;

    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
      animation-delay: 1.15s;
    }
  }
}
@keyframes indeterminate {
    0% {
      left: -35%;
      right:100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
}

