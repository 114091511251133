//////////////////////////////////////////////
// Colors and opacity can be gently transitioned.
//////////////////////////////////////////////
.background-color-transition-150 {
		-webkit-transition: background-color 150ms linear;
		transition: background-color 150ms linear;
}

.background-color-transition-350 {
		-webkit-transition: background-color 350ms linear;
		transition: background-color 350ms linear;
}

.background-color-transition-650 {
		-webkit-transition: background-color 650ms linear;
		transition: background-color 650ms linear;
}

.background-color-transition-1000 {
		-webkit-transition: background-color 1000ms linear;
		transition: background-color 1000ms linear;
}
 
