$manrope-font-path: "/assets/fonts/manrope/" !default;

/*
@font-face {
    font-family: 'Manrope';
    src: urlll("#{$manrope-font-path}Manrope-V.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
*/



@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-ExtraLight.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-Light.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-Light.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-Regular.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-Medium.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-SemiBold.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-Bold.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Manrope';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("#{$manrope-font-path}Manrope-ExtraBold.woff2") format("woff2"),
       url("#{$manrope-font-path}Manrope-ExtraBold.woff") format("woff");
}
