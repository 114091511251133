$roboto-font-path: "/assets/fonts/roboto/" !default;

/****************************************************************
ROBOTO
****************************************************************/
@font-face {
    font-family: "Roboto";
    src: url("#{$roboto-font-path}Roboto-Thin.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: "Roboto Light";
    src:  url("#{$roboto-font-path}Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto Regular";
    src: url("#{$roboto-font-path}Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto Medium";
    src: url("#{$roboto-font-path}Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url("#{$roboto-font-path}Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
}



.roboto {
  font-family: "Roboto";
}
