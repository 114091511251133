/*************************
* Tabs - Material 3 style
**************************/
._tabs-container-m3 {
    width: 100%;
    height: 48px;
    position: relative;
    box-sizing: border-box;

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;


    .item {
     // display: inline-block;
      justify-self: center;
      transition: .3s ease-in-out;
      box-sizing: border-box;
      width: 100%;

      .label {
        width: 100%;
        text-align: center;
        line-height: 48px;
        @extend ._title-small;
      }
      &.active {
        border-bottom: 3px solid #FF0000;
      }

    }  
  

}


._tabs-container-m3-w-icon {
    width: 100%;
    height: 64px;
    position: relative;
    box-sizing: border-box;

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;


    .item {
      justify-self: center;
      transition: .3s ease-in-out;
      box-sizing: border-box;

      .label {
        width: 100%;
        text-align: center;
        line-height: 28px;
        @extend ._title-small;
      }
      &.active {
      //  border-bottom: 2px solid #FF0000;
      }

    }  
  

}


._tab-m3-icon {
    height: 61px;
    padding-top: 12px;
    text-align: center;
    //padding-bottom: 12px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: none;

    .icon {
        //padding-bottom: 2px;
        i {
            font-size: 24px;
        }
    }

    .label {
        width: 100%;
        text-align: center;
        @extend ._title-small;
    }

}
 



/*************************
* Tabs - "Capsule" menu style
**************************/
._tab-capsule-bar {
    width: 100%;
    height: 72px;
    border-radius: 44px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;


    .item {
      display: inline-block;
      justify-self: center;
      text-align: center;
      line-height: 72px;
      box-sizing: border-box;
      @extend ._title-medium;
      border-radius: 44px;
      width: 100%;

      &:hover {
        background-color: rgba(0,0,0,0.1);
        font-weight: 500;
      }

    }  
      
}