$file-upload-card-height:         64px;
$file-upload-card-twoline-height:         72px;

._progress-card-one-line {
  background-color:  transparent;
	width: 100%;
	overflow:	hidden;
  box-sizing: border-box;
  position: relative;
  height: $file-upload-card-height;

  .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height:         $file-upload-card-height;

    .icon {
      flex:       0 0 48px;
      order:      1;
      height:     $file-upload-card-height;
      box-sizing: border-box;

      .icon-wrapper {
        line-height:  $file-upload-card-height;
        font-size:    32px;
        text-align:   center;
        margin-left:  4px;
        opacity:      1;
      }
    }

    .progress-wrapper {
      flex:       1;
      order:      2;
      padding:    16px;
      box-sizing: border-box;
      height:     $file-upload-card-height;
      overflow:   hidden;

      .label-wrapper {
        box-sizing:     border-box;
        width:          100%;

        display:        flex;
        flex-wrap:      nowrap;
        flex-direction: row;
        align-items:    stretch;
        overflow:       hidden;

        .label {
          @extend ._label-large;
          line-height:              32px;
          float:                    left;
          white-space:              nowrap;
          overflow:                 hidden;
          text-overflow:            ellipsis;
          flex:       1;
          order:      1;
        }

        .progress-perc {
          @extend ._label-large;
          line-height:              32px;
          float:                    right;
          text-align:               right;
          flex:                     1;
          order:                    1;
        }
      }

      .progress-bar {
        width:                    100%;
      }
    }

    .controls-wrapper {
      flex:       0 0 48px;
      order:      3;
      height:     $file-upload-card-height;
      box-sizing: border-box;

      .icon-wrapper {
        line-height:  $file-upload-card-height;
        font-size:    24px;
        text-align:   center;
        margin-right: 4px;
      }
    }
  }

}











._progress-card-two-line {
  background-color:  transparent;
	width: 100%;
	overflow:	hidden;
  box-sizing: border-box;
  position: relative;
  height: $file-upload-card-twoline-height;

  background:     rgba(0,0,0,0.1);

  .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height:         $file-upload-card-twoline-height;

    .icon {
      flex:       0 0 48px;
      order:      1;
      height:     $file-upload-card-twoline-height;
      box-sizing: border-box;

      .icon-wrapper {
        line-height:  $file-upload-card-twoline-height;
        font-size:    32px;
        text-align:   center;
        margin-left:  16px;
        opacity:      0.7;
      }
    }

    .progress-wrapper {
      flex:       1;
      order:      2;
      padding:    8px;
      padding-left:    16px;
      box-sizing: border-box;
      height:     $file-upload-card-twoline-height;
      overflow:   hidden;

      .heading {
        @extend ._label-large;
        line-height:              25px;
        float:                    left;
        width:                    95%;
        white-space:              nowrap;
        overflow:                 hidden;
        text-overflow:            ellipsis;
      }

      .label-wrapper {
        box-sizing:     border-box;
        width:          100%;
        display:        flex;
        flex-wrap:      nowrap;
        flex-direction: row;
        align-items:    stretch;
        overflow:       hidden;
        opacity: 0.7;

        .label {
          @extend ._label-large;
          line-height:              18px;
          float:                    left;
          white-space:              nowrap;
          overflow:                 hidden;
          text-overflow:            ellipsis;
          font-variant-numeric: tabular-nums;
          flex:       1;
          order:      1;
        }

        .progress-perc {
          @extend ._label-large;
          line-height:              18px;
          float:                    right;
          text-align:               right;
          flex:                     1;
          order:                    1;
        }
      }

      .progress-bar {
        width:                    100%;
      }
    }

    .controls-wrapper {
      flex:       0 0 48px;
      order:      3;
      height:     $file-upload-card-twoline-height;
      box-sizing: border-box;

      .icon-wrapper {
       line-height:  $file-upload-card-twoline-height - 8;
       font-size:    24px;
        text-align:   center;
       // opacity:      0.7;
        margin-right: 4px;
      }
    }
  }

}
