
._chip {
    width: max-content;
    height: 32px;
    border-radius: $radius-small;
    box-sizing: border-box;
    position: relative;
    line-height: 32px;
    vertical-align: top;
    white-space: nowrap;

    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    overflow: hidden;
    scroll-snap-align: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 12px;

    .leading-icon {
        box-sizing: border-box;
        overflow: hidden; 
        width: 24px;
        height: 32px;
        text-align: center;
        float: left;
        padding-left: 8px;
        //margin-right: 8px;
        i {
            font-size: 18px;
            line-height: 32px;
        } 
    }

    .avatar {
        box-sizing: border-box;
        overflow: hidden; 
        float: left;
        padding-left: -8px;
        padding-top: 4px;
        height: 32px;
        img {
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;
            border-radius: 12px;
            box-sizing: border-box;
        } 
    }


    .label {
        width: max-content;
        height: 32px; 
        overflow: hidden;
        padding-left: 8px; 
        padding-right: 8px;
        @extend ._label-large;
        line-height: 32px;
        box-sizing: border-box;
    }

    .trailing-icon {
        box-sizing: border-box;
        overflow: hidden; 
        width: 24px;
        height: 32px;
        text-align: center;
        float: right;
        i {
            font-size: 18px;
            line-height: 32px;
        }         
    }

    &.capsule {
        border-radius: $radius-large;
    }
}


._chip-set-row {
    height: 48px;
    padding-top: 8px;
    box-sizing: border-box;

    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: scroll;
    scroll-padding-left: 1rem;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
      }
  
}

/*

._chip {
    min-width: 1px;
    height: 32px;
    border-radius: $radius-small;
    box-sizing: border-box;
    position: relative;
    line-height: 32px;
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    overflow:         hidden;
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block;
    outline: 1px solid rgba(0,0,0,0.1);
    margin-right: 12px;

    .leading-icon {
        box-sizing: border-box;
        overflow: hidden;
       // width: 40px;
        font-size: 20px;
        line-height: 34px;
        vertical-align: top;
        display: inline-block;
    }

    .label {
        min-width: 1px;
        height: 56px;
        overflow: hidden;
        padding-left: 8px;
        padding-right: 8px;
        @extend ._label-large;
        display: inline-block;
        box-sizing: border-box;
    }

    .trailing-icon {
        
    }

    &.capsule {
        border-radius: $radius-large;
    }
}


._chip-set-row {
    width: 100%;
    height: 48px;
    padding-top: 8px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
      }
  
}

*/