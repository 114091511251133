

._blog-page-header {
    padding-top: 64px;

    .heading {
        @extend ._display-large;
        font-weight: 600;
        margin-bottom: 32px;
    }

    .page-author-wrapper {
        @extend .two-item-list-tile-icon-text;
        padding: 0;
        margin-bottom: 32px;
    }
}