$sacramento-font-path: "/assets/fonts/sacramento/" !default;


@font-face {
    font-family: 'Sacramento Regular';
    font-style: normal;
    src: url("#{$sacramento-font-path}Sacramento-Regular.ttf") format("truetype"),
         url('#{$sacramento-font-path}sacramento-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sacramento-font-path}sacramento-regular-webfont.woff') format('woff'); /* Modern Browsers */

    font-weight: 400;
}


.sacramento {
  font-family: 'sacramentoregular';
}
