$default-font-family: Arial, Helvetica, sans-serif;
$strong-font-weight: 600;


._display-small {
    font-family: $default-font-family;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
}

._display-medium {
    font-family: $default-font-family;
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 0;
}

._display-large {
    font-family: $default-font-family;
    font-size: 57px;
    font-weight: 400;
    letter-spacing: 0.4;
}

._display-very-large {
    font-family: $default-font-family;
    font-size: 96px;
    font-weight: 600;
    letter-spacing: 0.4;
}


._headline-small {
    font-family: $default-font-family;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    //line-height: 32px;
}

._headline-medium {
    font-family: $default-font-family;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    //line-height: 36px;
}

._headline-large {
    font-family: $default-font-family;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    //line-height: 40px;
}


._title-small {
    font-family: $default-font-family;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1;
}

._title-medium {
    font-family: $default-font-family;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15;
//    line-height: 24px;
}

._title-large {
    font-family: $default-font-family;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
}





._body-small {
    font-family: $default-font-family;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4;
}

._body-medium {
    font-family: $default-font-family;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25;
//    line-height: 20px;
}

._body-large {
    font-family: $default-font-family;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5;
}


._label-small {
    font-family: $default-font-family;
    font-size: 11px;
    font-weight: $strong-font-weight;
    letter-spacing: 0.5;
}

._label-medium {
    font-family: $default-font-family;
    font-size: 12px;
    font-weight: $strong-font-weight;
    letter-spacing: 0.5;
}

._label-large {
    font-family: $default-font-family;
    font-size: 14px;
    font-weight: $strong-font-weight;
    letter-spacing: 0.5;
}

