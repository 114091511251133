._dynamicclouds-background {
  position: relative;
  width:    100%;
  height:   100vh;
  transition: background 650ms linear;

  .magical-ellipse {
    width:            420px;
    height:           550px;
    border-radius:    60%;
    filter:           blur(120px);
    transform:        rotate(-30deg) translateZ(0);
    opacity:          0.9;
    position:         absolute;
    top:              50%;
    left:             50%;
    transition: opacity 650ms linear;
    transition: background 650ms linear;
    //-webkit-transition: all 2000ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }

}



.dynamicclouds-ellipse-grow-effect {
  animation: _dynamicclouds-ellipse-grow-effect 350ms ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes _dynamicclouds-ellipse-grow-effect {
  0% {
    border-radius:    50%;
    filter:           blur(0);
    width:            1px;
    height:           1px;
  }

  100% {
    border-radius:    60%;
    filter:           blur(120px);
    width:            420px;
    height:           550px;
  }
}


.dynamicclouds-ellipse-shrink-effect {
  animation: _ionskies-ellipse-shrink-effect 3000ms ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes _dynamicclouds-ellipse-shrink-effect {
  0% {
    border-radius:    60%;
    filter:           blur(120px);
    width:            420px;
    height:           550px;
  }

  100% {
    border-radius:    50%;
    filter:           blur(5px);
  //  width:            10px;
  //  height:           10px;
  }
}
