//////////////////////////////////////////////
// Colors and opacity can be gently transitioned.
//////////////////////////////////////////////
.opacity-transition-150 {
		-webkit-transition: opacity 150ms linear;
		transition: opacity 150ms linear;
}

.opacity-transition-350 {
		-webkit-transition: opacity 350ms linear;
		transition: opacity 350ms linear;
}

.opacity-transition-650 {
		-webkit-transition: opacity 650ms linear;
		transition: opacity 650ms linear;
}


.opacity-transition-1000 {
		-webkit-transition: opacity 1000ms linear;
		transition: opacity 1000ms linear;
}


