._file-upload-container {
    width: 100%;
    height: 800px;
    box-sizing: border-box;
    position: relative;
	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;


    .drop-target {
		order: 1;
		flex: 0 0 176px;
		box-sizing: border-box;
		overflow: hidden;
        width: 100%;
    }

    .file-list-container {
        width: 100%;
		order: 2;
		flex: 1;
		overflow: hidden;
		min-height: 0;
    }
}


._file-drop-target {

    width: 100%;
    height: 176px;
    position: relative;
    border: 2px dashed rgba(0,0,0,0.5);
    box-sizing: border-box;
    overflow: hidden;

    .info-block {
      //  background-color: aqua;
        width: 100%;
        height: 96px;
        margin: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-48px);
        transform: translateY(-48px);
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 32px;

        img {
            width: 96px;
            height: auto;
        }

        i {
            font-size: 56px;
        }
    
        .heading {
         //   padding-top: 6px;
            @extend ._title-medium;
        }

    }


}

._file-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
}