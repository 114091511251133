
._navigation-bar {
    width: 100%;
    height: 80px;
    position: relative;
    box-sizing: border-box;
    z-index: 1010;

    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    padding-top: 12px;
    padding-right: 8px;

    .item {
      display: inline-block;
      justify-self: center;
    }  
  
}
  

