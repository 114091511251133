$blinker-font-path: "/assets/fonts/blinker/" !default;


/*** Blinker**/
@font-face {
    font-family: 'Blinker Bold';
    src: url("#{$blinker-font-path}Blinker-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Blinker Semi Bold';
    src: url("#{$blinker-font-path}Blinker-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'Blinker Light';
    src: url("#{$blinker-font-path}Blinker-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Blinker Regular';
    src: url("#{$blinker-font-path}Blinker-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Blinker Thin';
    src: url("#{$blinker-font-path}Blinker-Thin.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
