._coreui-calendar {
    font-family: 'Arial, Helvetica, sans-serif';
    position: relative;
    max-width: 100%; 
    min-width: 320px;
    height: 424px;

    background: rgba(0,0,0,0);
    color: #000000;

    box-sizing: border-box;
    overflow: hidden;
    font-weight: normal;
    border-radius: $radius-medium;
    padding: 8px;

    .controls {
        display:          flex;
        flex-wrap:        nowrap;
        flex-direction:   row;
        align-items:      stretch;
        overflow:         hidden;
        width:            100%;
        position:         relative;
        height:           40px;
        margin-bottom:    16px;
        -webkit-touch-callout: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
      
        .prev {
            order: 1;
            flex: 0 0 56px;
            float: left;
            padding-top: 10px;
            padding-left: 8px;
            box-sizing: border-box;
        }

        .year-month {
            order: 2;
            flex: 1 0 auto;
            text-align: center;
            box-sizing: border-box;
            line-height: 40px;
        }

        .next {
            order: 3;
            flex: 0 0 56px;
            text-align: right;
            padding-top: 10px;
            padding-right: 10px;
            box-sizing: border-box;
        }

    }



    .calendar-body {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;
        box-sizing: border-box;

        .item {
           // padding: 4px;
            min-height: 30px;
            line-height: 30px;
            border: 1px solid transparent;
            margin: 10px 0px 0px;  
            box-sizing: border-box;
            position: relative;
            -webkit-touch-callout: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            cursor: pointer;
            

            .day {
                text-align: center;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                background-color: rgb(95, 24, 227, 0.3);
                box-sizing: border-box;
                display: inline-block;
            }


            .selected {
                position: relative;
                top: 2px;
                left: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-sizing: border-box;
                background-color: rgb(95, 24, 227);
                opacity: 1;
                z-index: 1;
                color: #FFFFFF;
                -webkit-touch-callout: none;
                user-select: none;
                -webkit-tap-highlight-color: transparent;
                cursor: pointer;
        
            }
    
        }  


    }
    

}


.calendar .calendar-inner .calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
}

.calendar .calendar-inner .calendar-body div {
    padding: 4px;
    min-height: 30px;
    line-height: 30px;
    border: 1px solid transparent;
    margin: 10px 2px 0px;
}

.calendar .calendar-inner .calendar-body div:nth-child(-n+7) {
    border: 1px solid transparent;
    border-bottom: 1px solid var(--weekdays-border-bottom-color);
}

.calendar .calendar-inner .calendar-body div:nth-child(-n+7):hover {
    border: 1px solid transparent;
    border-bottom: 1px solid var(--weekdays-border-bottom-color);
}

.calendar .calendar-inner .calendar-body div>a {
    color: var(--calendar-font-color);
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.calendar .calendar-inner .calendar-body div:hover {
    border: 1px solid var(--calendar-date-hover-color);
    border-radius: 4px;
}

.calendar .calendar-inner .calendar-body div.empty-dates:hover {
    border: 1px solid transparent;
}

.calendar .calendar-inner .calendar-controls {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.calendar .calendar-inner .calendar-today-date {
    display: grid;
    text-align: center;
    cursor: pointer;
    margin: 3px 0px;
    background: var(--calendar-current-date-color);
    padding: 8px 0px;
    border-radius: 10px;
    width: 80%;
    margin: auto;
}

.calendar .calendar-inner .calendar-controls .calendar-year-month {
    display: flex;
    min-width: 100px;
    justify-content: space-evenly;
    align-items: center;
}

.calendar .calendar-inner .calendar-controls .calendar-next {
    text-align: right;
}

.calendar .calendar-inner .calendar-controls .calendar-year-month .calendar-year-label,
.calendar .calendar-inner .calendar-controls .calendar-year-month .calendar-month-label {
    font-weight: 500;
    font-size: 20px;
}

.calendar .calendar-inner .calendar-body .calendar-today {
    background: var(--calendar-today-color);
    border-radius: 4px;
}

.calendar .calendar-inner .calendar-body .calendar-today:hover {
    border: 1px solid transparent;
}

.calendar .calendar-inner .calendar-body .calendar-today a {
    outline: 2px solid var(--calendar-today-innerborder-color);
}

.calendar .calendar-inner .calendar-controls .calendar-next a,
.calendar .calendar-inner .calendar-controls .calendar-prev a {
    color: var(--calendar-font-color);
    font-family: arial, consolas, sans-serif;
    font-size: 26px;
    text-decoration: none;
    padding: 4px 12px;
    display: inline-block;
    background: var(--calendar-nextprev-bg-color);
    margin: 10px 0 10px 0;
}

.calendar .calendar-inner .calendar-controls .calendar-next a svg,
.calendar .calendar-inner .calendar-controls .calendar-prev a svg {
    height: 20px;
    width: 20px;
}

.calendar .calendar-inner .calendar-controls .calendar-next a svg path,
.calendar .calendar-inner .calendar-controls .calendar-prev a svg path{
    fill: var(--next-prev-arrow-color);
}

.calendar .calendar-inner .calendar-body .prev-dates,
.calendar .calendar-inner .calendar-body .next-dates {
    color: var(--calendar-prevnext-date-color);
}

.calendar .calendar-inner .calendar-body .prev-dates:hover,
.calendar .calendar-inner .calendar-body .next-dates:hover {
  border: 1px solid transparent;
  pointer-events: none;
}




._chevron::before {
	border-style: solid;
	border-width: 2px 2px 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;

}

._chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

._chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

._chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}
