$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.container .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}


.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
  //  padding: 0 $gutter-width / 2;
    min-height: 1px;


    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }


    .s0 {
      width: 0;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .np {
      padding: 0;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        margin-left: auto;
        left: auto;
        right: auto;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-s#{$i} {
        margin-left: $perc;
      }
      &.pull-s#{$i} {
        right: $perc;
      }
      &.push-s#{$i} {
        left: $perc;
      }
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} {
          margin-left: $perc;
        }
        &.pull-m#{$i} {
          right: $perc;
        }
        &.push-m#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} {
          margin-left: $perc;
        }
        &.pull-l#{$i} {
          right: $perc;
        }
        &.push-l#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }
  }

  .no-padding-row {
    margin-bottom: 0;
  }

  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-right-only {
    padding-left: 0;
    padding-right: $gutter-width / 2;;
  }

  .np {
    padding-left: 0;
    padding-right: 0;
  }

}


/*
https://uxdesign.cc/whitespace-in-ui-design-44e332c8e4a
https://www.remon.design/baseline-grids-in-digital-ui-design/#:~:text=A%20baseline%20grid%20has%20supporting,spaces%20in%20your%20design%20system.
https://uxdesign.cc/ui-cheat-sheet-spacing-friendships-e37a6fccc407

https://medium.com/swlh/the-comprehensive-8pt-grid-guide-aa16ff402179


*/