
.origin-destination-progressbar {
    width:        100%;
    height:       32px;
    line-height:  32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
  	overflow: hidden;
    font-size: 20px;

    .origin {
      flex: 0 0 72px;
      font-weight: 600;
    }

    .fillspace {
      flex: 1 0 auto;
    }

    .progress {
      flex: 1 0 auto;
      height: 2px;
      background-color: rgba(0,0,0,0.15);
      position: relative;

      &.noline {
        background-color: rgba(0,0,0,0);
      }

      &.dark {
        background-color: rgba(255,255,255,0.2);
      }

      i {
        -webkit-transform: rotate(90deg);
        position: absolute;
        top: -14px;
        left: 10%;
      }

    }

    .destination {
      flex: 0 0 72px;
      text-align: right;
      font-weight: 600;
    }
}


._travel-icon-progress {
  width:        100%;
  height:       40px;
  line-height:  40px;
  position: relative;
  box-sizing: border-box !important;

  .progress-line {
    height: 2px;
    width: 100%;
    background-color: rgba(0,0,0,0.15);
    position: absolute;
    top: 18px;

    &.noline {
      background-color: rgba(0,0,0,0);
    }

    &.dark {
      background-color: rgba(255,255,255,0.2);
    }
  }

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: left 1000ms ease-in-out;
    transform: translateZ();
    font-size: 24px;
    &.rotate90 {
      -webkit-transform: rotate(90deg);
    }
  }


}
