
._image-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}