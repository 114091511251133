
._article-page-image-fullwidth {
    width: 100%;
    margin-bottom: 40px;

    img {
        width: 100%;
        height: auto;
        object-fit: contain;    
    }

    .caption {
        margin-top: 8px;
        text-align: center;
        font-style: italic;
        @extend ._body-medium;   
    }
}