
._menu-item-no-icon-collapsible {
    @extend .list-tile-item;
    height: 48px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    padding-left: 16px;
    border-radius: 24px;
    box-sizing: border-box;
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    .label {
      order: 1;
      flex: 1 0;
      color: #000000;
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend ._label-large;
    }
  
    .secondary-action {
      order: 2;
      flex: 0 0 auto;
      @extend .list-tile-secondary-action-48;
    }

  
}

._collapsible-header {
  display: block;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  @extend .all-motion-animation;
}


._collapsible-body {
  display: none;
  box-sizing: border-box;
  padding: 0;
  width: 100%;

  &.show {
    display:list-item;
  }
  &.hide {
    display: none;
  }

  .menu-list-container {
    list-style: 		      none;
    padding: 8px;
    padding-top: 0;
  }
}