$dmsans-font-path: "/assets/fonts/dmsans/" !default;


@font-face {
  font-family: 'DM Sans Regular';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("#{$dmsans-font-path}DMSans-Regular.ttf") format("truetype")
}

@font-face {
  font-family: 'DM Sans Medium';
  font-style:  normal;
  font-weight: 650;
  font-display: swap;
  src: url("#{$dmsans-font-path}DMSans-Medium.ttf") format("truetype")
}

@font-face {
  font-family: 'DM Sans Bold';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("#{$dmsans-font-path}DMSans-Bold.ttf") format("truetype")
}
