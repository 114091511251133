$inter-font-path: "/assets/fonts/inter/" !default;

/*
@font-face {
    font-family: 'Inter';
    src: urlll("#{$inter-font-path}Inter-V.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
*/


@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Thin.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Thin.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-ThinItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-ExtraLight.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-ExtraLightItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Light.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Light.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-LightItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-LightItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Regular.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Italic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Medium.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-MediumItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-SemiBold.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-SemiBoldItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Bold.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-BoldItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-ExtraBold.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-ExtraBoldItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-Black.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("#{$inter-font-path}Inter-BlackItalic.woff2") format("woff2"),
       url("#{$inter-font-path}Inter-BlackItalic.woff") format("woff");
}
