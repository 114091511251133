.list-tiles-container {
    list-style: none;
    box-sizing: border-box;
    transform: translateZ(0);
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
}

.list-tile-item {
    background-color: transparent;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: margin-bottom 250ms ease-in;
    margin-bottom: 8px;
}

.list-tile-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.list-tile-space-8 {
    margin-bottom: 8px;
}

.list-tile-secondary-action-48 {
    display: inline-block;
    height: 48px;
    line-height: 48px;
    padding-right: 24px;
    padding-left: 16px;

    color: #000000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    i {
        font-size: 20px;
        opacity: 0.7;
        line-height: inherit;
    }
}

.list-tile-secondary-action-56 {
    height: 56px;
    line-height: 56px;
    padding-right: 24px;
    padding-left: 16px;
    color: #000000;
    box-sizing: border-box;

    &.inset-divider {
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
    }

    .text {
        color: #000000;
        line-height: 56px;
        @extend ._label-medium;
        display: inline-block;
        vertical-align: top;
    }

    i {
        font-size: 16px;
        line-height: inherit;
        opacity: 0.7;
    }
}

.list-tile-secondary-action-64 {
    height: 64px;
    line-height: 64px;
    padding-right: 24px;
    padding-left: 16px;
    color: #000000;
    box-sizing: border-box;

    &.inset-divider {
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
    }
    .text {
        font-size: 12px;
        color: #000000;
        line-height: 64px;
        opacity: 0.7;
        display: inline-block;
        vertical-align: top;
    }
    i {
        font-size: 16px;
        line-height: inherit;
        opacity: 0.7;
    }
}

.list-tile-secondary-action-72 {
    height: 72px;
    line-height: 72px;
    padding-right: 24px;
    padding-left: 16px;
    color: #000000;
    box-sizing: border-box;

    &.inset-divider {
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
    }
    .text {
        font-size: 12px;
        color: #000000;
        line-height: 72px;
        opacity: 0.7;
        display: inline-block;
        vertical-align: top;
    }
    i {
        font-size: 16px;
        line-height: inherit;
        opacity: 0.7;
    }
}

.list-tile-secondary-action-88 {
    height: 88px;
    padding-right: 24px;
    padding-left: 16px;
    //padding-top: 8px;
    color: #000000;
    box-sizing: border-box;

    &.inset-divider {
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
    }
    .text {
        font-size: 12px;
        color: #000000;
        opacity: 0.7;
        line-height: 44px;
    }
    i {
        font-size: 16px;
        opacity: 0.7;
        line-height: 44px;
    }
}


