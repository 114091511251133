
._fullscreen-radar-blip-search-popup {
   position: relative;
   width: 100%;
   height: 100vh;

  .heading {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    @extend ._headline-medium;
    color: #FFFFFF;
  }

  .radar {
    margin: 0;
    position: absolute;
    bottom: 30%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-20px);
    border-radius: 28px;
    background-color: #FFFFFF;
    text-align: center;
    box-sizing: border-box;

    i {
      line-height: 40px;
      font-size: 24px;
      color: #000000;
    }

    &.radar::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      border-radius: inherit;
      transform: translateZ(0);
      transition: opacity .3s, transform .3s;
      animation: circle-radar-pulse-animation 750ms ease-in infinite;
      z-index: -1;
    }      
  }
  
}

@keyframes circle-radar-pulse-animation {
  0% {
    opacity: 0.2;
    transform: translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(12) translateZ(0);
  }
}
