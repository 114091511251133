$ebgaramond-font-path: "/assets/fonts/ebgaramond/" !default;


/*** Blinker**/
@font-face {
    font-family: 'EBGaramond Bold';
    src: url("#{$ebgaramond-font-path}EBGaramond-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'EBGaramond Bold Italic';
    src: url("#{$ebgaramond-font-path}EBGaramond-BoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'EBGaramond Extra Bold';
    src: url("#{$ebgaramond-font-path}EBGaramond-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'EBGaramond Extra Bold Italic';
    src: url("#{$ebgaramond-font-path}EBGaramond-ExtraBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'EBGaramond Semi Bold';
    src: url("#{$ebgaramond-font-path}EBGaramond-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'EBGaramond Semi Bold Italic';
    src: url("#{$ebgaramond-font-path}EBGaramond-SemiBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'EBGaramond Medium';
    src: url("#{$ebgaramond-font-path}EBGaramond-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'EBGaramond Medium Italic';
    src: url("#{$ebgaramond-font-path}EBGaramond-MediumItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'EBGaramond Regular';
    src: url("#{$ebgaramond-font-path}EBGaramond-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
