._fab-button {
    max-height: 56px;
    width: 56px;
    border: none;
    border-radius: 16px;
    text-align: center;
    background-color: #00cccc;
    color: #ffffff;
    white-space: nowrap;
    font-family: Arial, Helvetica, sans-serif;
  
    //gt rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
  
    // font
    vertical-align: middle;
  
    // Gets rid of tap active state
    -webkit-tap-highlight-color: transparent;
  
    cursor: pointer;
    position: relative;
  

    .icon {
      line-height: 56px;
      font-size: 24px;
      position: relative;
      box-sizing: border-box;
    }

    .touch-overlay {
        height: 20px;
        width: 20px;
        border: none;
        border-radius: 6px;
        background-color: rgba(0,0,0,0);
        z-index: 1;
        position: absolute;
        top: 18px;
        left: 18px;
    }
  
    .activity-spin {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 40px; 
      border-radius: 50%; 
      z-index: 2;
      border: 1px solid rgba(0,0,0,0.4);
      border-top:1px solid rgba(0,0,0,0);
      animation: preloader-spinner-spin 500ms infinite ease-in-out;
    }

    &.small {
        height: 40px;
        width: 40px;

        .icon {
          line-height: 44px;
          font-size: 24px;
        }
    
        .touch-overlay {
            height: 15px;
            width: 15px;
            border-radius: 4px;
            top: 10px;
            left: 10px;
        }


    }

    &.large {
        max-height: 96px;
        width: 96px;

        .icon {
          line-height: 96px;
          font-size: 40px;
        }

        .touch-overlay {
            height: 40px;
            width: 40px;
            border-radius: 12px;
            top: 28px;
            left: 28px;
        }
    }  
    
    &.round {
        border-radius: 50%;
    }
    
    .ppulse {
        overflow: initial;
        z-index: 10;
      }
      &.ppulse::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: inherit;
        border-radius: inherit;
        transition: opacity .3s, transform .3s;
        animation: btn-pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
        z-index: -1;
      }
   
}