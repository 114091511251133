///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.three-item-list-tile-icon-text {
  @extend .list-tile-item;
  height: 88px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  .icon-wrapper {
    order: 1;
    flex: 0 0 auto;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;
   // padding-left: 16px;
    color: #000000;
    box-sizing: border-box;
    text-align: center;
    padding-top: 16px; 
    i {
      font-size: 20px;
      margin-right: 32px;
    }
  }

  .circle-wrapper {
    order: 1;
    flex: 0 0 auto;
   // padding-left: 8px;
    padding-top: 16px;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
      line-height: 40px;
      box-sizing: border-box;
      text-align: center;

      i {
        font-size: 18px;
        line-height: inherit;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
  }

  .content {
    order: 1;
    flex: 1 0;
    height: 88px;
    box-sizing: border-box;
    overflow: hidden;
    float: left;
    padding-top: 24px; 

    .heading {
      font-size: 16px;
      color: #000000;
      line-height: 0;
      font-weight: 600;
      padding-bottom: 12px; 
    }

    .secondary-line {
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      word-wrap: normal;
      opacity: 0.7;
      font-weight: 400;
    }

  }

  .secondary-action {
    order: 2;
    flex: 0 0 auto;
    float: right;
    @extend .list-tile-secondary-action-88;
  }
}
