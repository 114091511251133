$la-appbar-top-height:  64px;

._la-navbar-medium {
  display:          flex;
  flex-wrap:        nowrap;
  flex-direction:   row;
  align-items:      stretch;
  overflow:         hidden;
  position:         relative;
  box-sizing:       border-box;
  color:            #000000;
  background-color: rgba(0,0,0,0);
  width:            100%;
  height:           $la-appbar-top-height;
  line-height:      $la-appbar-top-height;
  padding-left:     24px;
  padding-right:    8px;
  z-index: 1;
  transition:  all $motion-duration-short-3 cubic-bezier(0.3, 0.0, 0.8, 0.15);


  &.noborder {
    border-bottom:    none;
  }

  .logo {
    flex: 0 0 72px;
    order: 1;
    float: left;
    padding-top:  8px;
    padding-bottom:  4px;
    user-select:  none;

    img {
      width:      auto;
      height:     $la-appbar-top-height - 16;
    }

  }

  .back-icon {
    flex: 0 0 72px;
    order: 1;
    float: left;
    padding-left: 16px;
    user-select:  none;

    i {
      line-height:        $la-appbar-top-height;
    }
  }

  .fill-remaining-space {
    order: 2;
    flex: 1 0 auto;
    text-align: center;
  }


  .menu-items {
    order: 3;
    flex: 0 0 auto;
    float:  left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 56px;

    .item {
      height: $la-appbar-top-height;
      padding-left: 24px;
      padding-right: 24px;
      display: inline-block;

      // font
      font-weight: 600;
      color: #000000;
      opacity: 0.9;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.selected {
        border-bottom: 4px solid #EA5433;
        opacity:       1;
      }

      &:focus {
        background-color:  rgba(0,0,0,0.2);
        border-radius: 24px;
      }

      &:hover {
        background-color:  rgba(0,0,0,0.05);
      }
    }
  }



  .action-items {
    flex: 0 0 auto;
    order: 3;
    float: right;
    padding-right:  24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 28px;

    .item {
      width:              40px;
      height:             $la-appbar-top-height;
      line-height:        $la-appbar-top-height;
      padding-left:       16px;
      padding-top: 12px;

      display: inline-block;
      text-transform: uppercase;
      color:             #FFFFFF;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &.icon {
        text-align: center;
      }

      &.button {
        position: absolute;
        &:hover {
          background-color:  rgba(0,0,0,0);
        }
      }

      i {
        font-size: 24px;
        line-height: inherit;
        opacity: 0.7;
      }


    }
  }
  

  .text-items {
    flex: 0 0 auto;
    order: 3;
    float: right;
    padding-right:  24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 28px;

    .item {
      height:             $la-appbar-top-height;
      line-height:        $la-appbar-top-height;
      padding-left:       16px;
      padding-right:      16px;
      text-align:         center;
      font-size:          16px;
      font-weight:        600;
      display: inline-block;
      text-transform: uppercase;
      color:             #FFFFFF;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;

    }
  }


  .team-widget-wrapper {
    flex: 0 0 auto;
    order: 4;
    padding-right: 32px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }


  .avatar-wrapper {
    height:             $la-appbar-top-height;
    flex: 0 0 auto;
    order: 5;
    float: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 12px;
    padding-right: 16px;
    padding-left: 12px;

    .name-field {
      float: left;
      text-align: right;
      font-size:      18px;
      font-weight:    500;
      text-transform:     uppercase;

      line-height:        $la-appbar-top-height;
      height:             $la-appbar-top-height;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width:    128px;
    }

    .avatar {
      height:             $la-appbar-top-height;
      line-height:        $la-appbar-top-height;
      color:              #FFFFFF;
      float: right;

      .circle {
        width:  44px;
        height:  44px;
        border-radius: 22px;
      }

      img {
        width:  40px;
        height:  40px;
        border-radius: 20px;
      }

    }
  
  }    

  .hamburger-wrapper {
    height:             $la-appbar-top-height;
    line-height:        $la-appbar-top-height;
    flex: 0 0 64px;
    order: 3;
    float: right;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 8px;
    text-align: center;

    .border-circle {
      border-radius: 50%;
      width:  $la-appbar-top-height - 16;
      height:             $la-appbar-top-height - 16;
      display: inline-block;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      i {
        font-size: 32px;
        line-height:        $la-appbar-top-height - 16;
        opacity: 0.9;
      }

    }

    i {
      font-size: 36px;
      line-height:        $la-appbar-top-height;
      opacity: 0.9;
    }
  }


}


.hamburger-btn-press-effect {
	animation: hamburger-btn-pressed-anim 250ms cubic-bezier(0.24, 0, 0.38, 1);
}
@keyframes hamburger-btn-pressed-anim {
  0% {
    background-color: rgba(0,0,0,0.1);
  }
  60% {
    background-color: rgba(0,0,0,0.05);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}

