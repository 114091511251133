
._clients-list-carousel {
    height: 96px;
    box-sizing: border-box;

    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: scroll;
    scroll-padding-left: 1rem;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
    }
  

    ._client-logo-item {
        width: 200px;
        height: 96px;
        padding: 24px;
        box-sizing: border-box;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: grayscale(100%);
        }
    }

}