
._side-sheet {
    width: 100%;
    height: 100vh;
    padding: 24px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    .header-bar {
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
                
        .icon-wrapper {
            order: 1;
            flex: 0 0 auto;
            overflow: hidden;
            display: inline-block;
            box-sizing: border-box;
            color: #000000;
            box-sizing: border-box;
            text-align: center;
        
            i {
            font-size: 20px;
            line-height: inherit;
            margin-right: 12px;
            }
        }
          
        
        .content {
            order: 2;
            flex: 1 0;
            box-sizing: border-box;
            color: #000000;
            vertical-align: top;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            @extend ._title-medium;
        }
        
        .close-icon {
            order: 3;
            flex: 0 0 auto;
            padding-left: 16px;
            color: #000000;
            box-sizing: border-box;
                
            i {
                font-size: 22px;
                line-height: inherit;
            }
                }
    }


    .actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 64px;
        padding-top: 24px;
        padding-left: 24px;

        .button {
            display: inline-block;
            margin-right: 12px;
        }
    }
}