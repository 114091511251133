$questrial-font-path: "/assets/fonts/questrial/" !default;


/*** Aileron**/
@font-face {
    font-family: 'Questrial Regular';
    src: url("#{$questrial-font-path}Questrial-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
