///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.single-item-list-tile-leading-image {
  @extend .list-tile-item;
  height: 72px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  &.wide {
    .image-wrapper {
      padding-left: 0;
      img {
        min-width: 100px;
        max-width: 100px;
      }
    }
    .content {
      flex: 0 0 244px;
    }
  }

  .image-wrapper {
    order: 1;
    flex: 0 0 auto;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 8px;
    box-sizing: border-box;

    img {
      min-width: 56px;
      max-width: 56px;
      min-height: 56px;
      max-height: 56px;
      object-fit: fill;
      margin-right: 16px;
    }
  }

  .content {
    order: 2;
    flex: 0 0 280px;

    float: right;
    height: 72px;
    box-sizing: border-box;
    overflow: hidden;

    &.inset-divider {
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
    }

    .primary-text {
      width: 100%;
      color: #000000;
      line-height: 72px;
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .secondary-action {
    order: 3;
    flex: 0 0 auto;
    @extend .list-tile-secondary-action-72;
  }
}

.shaded {
  background-color: rgba(0, 0, 0, 0.2);
}
