._saas-div-container {
	width: 100%;
	height: 100vh;
	position: relative;
	left: 0;
	top: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;

    .toolbar-wrapper {
		order: 1;
		flex: 0 0 72px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;

    }

    .content-wrapper {
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;

		order: 2;
		flex: 1;
		min-height: 0;
        
        padding-bottom: 96px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
    
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
        }
    }


}
