///////////////////////////////////////////////////
// Slider Sheets
///////////////////////////////////////////////////
.slider-sheet {

  position: fixed;
  transform: translateZ(0);
  z-index: 1;
  
  &.left {
    top: 0;
    left: 0;
    border-top-right-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    transition: left 450ms cubic-bezier(0.2, 0.0, 0, 1.0);
  }

  &.right {
    top: 0;
    right: 0;
    border-top-left-radius: $radius-large;
    border-bottom-left-radius: $radius-large;
    transition: right 450ms cubic-bezier(0.2, 0.0, 0, 1.0);
//    transition: width $motion-duration-medium-1 cubic-bezier(0.2, 0.0, 0, 1.0);
  }

  &.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
    transition: all $motion-duration-medium-1 cubic-bezier(0.2, 0.0, 0, 1.0);

  //  transition: height $motion-duration-medium-1 cubic-bezier(0.2, 0.0, 0, 1.0);
  }

  &.top {
    top: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    transition: height $motion-duration-medium-1 cubic-bezier(0.2, 0.0, 0, 1.0);
  }

  .slider-sheet-wrapper {
    position:            relative;
  	background-color:    #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
   // transition: all 1750ms ease-in-out;

    &.left {
      border-top-right-radius: $radius-large;
      border-bottom-right-radius: $radius-large;  
    }
    &.right {
      border-top-left-radius: $radius-large;
      border-bottom-left-radius: $radius-large;  
    }
    &.bottom {
      border-top-left-radius: $radius-large;
      border-top-right-radius: $radius-large;
    }
    &.top {
      border-bottom-left-radius: $radius-large;
      border-bottom-right-radius: $radius-large;
    }
  }

}
