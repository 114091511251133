._avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    box-sizing: border-box;
    line-height: 32px;
    text-align: center;

    img {
        width: 30px;
        height: 30px;
        border-radius: 15px;
    }

    i {
        font-size: 20px;
    }
}

._avatar-40 {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-sizing: border-box;
    line-height: 42px;
    text-align: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }

    i {
        font-size: 24px;
    }
}

