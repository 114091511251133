///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.single-item-list-tile {
  @extend .list-tile-item;
  height: 48px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  .primary-text {
    order: 1;
    flex: 1 0;
    font-size: 16px;
    color: #000000;
    line-height: 48px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .secondary-action {
    order: 2;
    flex: 0 0 auto;
    float: right;
    width: 48px;
    @extend .list-tile-secondary-action-48;
  }
}
