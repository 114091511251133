.button-press-animation {
	animation: btn-pressed-anim $motion-duration-medium-1 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes btn-pressed-anim {
  0% {
    opacity: 0.9;
    transform: scale(0.98);
  }
  60% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.button-press-animation-2 {
	animation: btn-pressed-anim-2 $motion-duration-medium-1 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes btn-pressed-anim-2 {
  0% {
    opacity: 0.7;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}



.fab-touch-animation {
	animation: fab-touch-anim $motion-duration-short-3 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fab-touch-anim {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.05, 1.05, 1);
    }
  
    40% {
      transform: scale3d(0.95, 0.95, 1);
    }
  
    50% {
      transform: scale3d(1.05, 1.05, 1);
    }
  
    65% {
      transform: scale3d(0.95, 0.95, 1);
    }
  
    75% {
      transform: scale3d(1.05, 1.05, 1);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }


  .fab-ripple-animation {
	animation: fab-ripple-anim $motion-duration-medium-3 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fab-ripple-anim {  
    from {
        background-color: rgba(255,255,255,0.2);
    }

    to {
        transform: scale(4);
        opacity: 0.8;
    }
  }





  .button-pulse-effect {
    overflow: initial;
    position: relative;
    z-index: 10;
  }
  .button-pulse-effect::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity .3s, transform .3s;
    animation: btn-pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
  }
  
  @keyframes btn-pulse-animation {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
  
    50% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }