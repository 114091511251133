$metropolis-font-path: "/assets/fonts/metropolis/" !default;


@font-face {
    font-family: 'Metropolis Regular';
    src: url("#{$metropolis-font-path}Metropolis-Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Regular Italic';
    src: url("#{$metropolis-font-path}Metropolis-RegularItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Medium';
    src: url("#{$metropolis-font-path}Metropolis-Medium.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Medium Italic';
    src: url("#{$metropolis-font-path}Metropolis-MediumItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Bold';
    src: url("#{$metropolis-font-path}Metropolis-Bold.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Bold Italic';
    src: url("#{$metropolis-font-path}Metropolis-BoldItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Extra Bold';
    src: url("#{$metropolis-font-path}Metropolis-Bold.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Extra Bold Italic';
    src: url("#{$metropolis-font-path}Metropolis-BoldItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Black';
    src: url("#{$metropolis-font-path}Metropolis-Black.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Semi Bold';
    src: url("#{$metropolis-font-path}Metropolis-SemiBold.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Extra Bold Italic';
    src: url("#{$metropolis-font-path}Metropolis-SemiBoldItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Thin';
    src: url("#{$metropolis-font-path}Metropolis-Thin.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Thin Italic';
    src: url("#{$metropolis-font-path}Metropolis-ThinItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Light';
    src: url("#{$metropolis-font-path}Metropolis-Light.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Light Italic';
    src: url("#{$metropolis-font-path}Metropolis-LightItalic.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Extra Light';
    src: url("#{$metropolis-font-path}Metropolis-ExtraLight.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Extra Light Italic';
    src: url("#{$metropolis-font-path}Metropolis-ExtraLightItalic.otf");
    font-weight: normal;
    font-style: normal;
}
