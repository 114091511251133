
._navigation-drawer {
    width: 100%;
    height: 100vh;
    border-top-right-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    overflow: hidden;

    .headline {
        box-sizing: border-box;
        margin-top: 24px;
        margin-bottom: 16px;
        margin-left: 16px;
        @extend ._title-small;
    }

    .menu-control {
        height: 56px;
        width: 100%;
        padding-left: 16px;
        line-height: 56px;
        color: #000000;
      
        i {
        font-size: 20px;
        line-height: inherit;
        margin-right: 28px;
        }      
    }


    .menu-container {
        list-style: none;
        box-sizing: border-box;
        transform: translateZ(0);
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    
}