$josefin-font-path: "/assets/fonts/josefin/" !default;


@font-face {
    font-family: "Josefin Sans";
    src: local(Julius Sans One), url('#{$josefin-font-path}JosefinSans-VariableFont_wght.ttf');
    src: url("#{$josefin-font-path}JosefinSans-VariableFont_wght.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Josefin Sans Italic";
    src: local(Julius Sans One), url('#{$josefin-font-path}JosefinSans-Italic-VariableFont_wght.ttf');
    src: url("#{$josefin-font-path}JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
    font-weight: 500;
}
