
$appui-page-header-height: 64px;




._appui-page-header {

    width: 100%;
   // height: 112px;
    padding-top: 24px;


    .guide-heading {
        margin-bottom: 48px;
        .heading {
            @extend ._display-large;
        }
        .subheading {
            @extend ._title-large;
            line-height: 44px;
        }
    }


    .heading-wrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: $appui-page-header-height;
        line-height: $appui-page-header-height;
        transition: all $motion-duration-short-3 cubic-bezier(0.3, 0, 0.8, 0.15);
    
        .page-heading {
            flex: 0 0 480px;
            order: 1;
            float: left;
            line-height: $appui-page-header-height;
            @extend ._display-medium;
        }

        .search-wrapper {
            flex: 1;
            order: 2;
            float: right;
            padding-top: 4px;
            user-select: none;
        }
        
    }

 
}
